<template>
  <div class="contain">
    <div class="mainContent">
      <el-form ref="checkForm" :model="formInline" label-width="100px">
        <el-row class="search">
          <el-col :span="18">
            <el-row>
              <el-col :span="6">
                <el-form-item label="登录账号:" prop="userName">
                  <el-input v-model="formInline.userName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="用户名称:" prop="realName">
                  <el-input v-model="formInline.realName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="操作类型:" prop="type">
                  <el-input v-model="formInline.type"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="查询时间">
                  <el-date-picker
                    v-model="formInline.startEnd"
                    type="daterange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="操作模块:" prop="module">
                  <el-input v-model="formInline.module"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <el-row>
              <el-col :span="24">
                <el-form-item>
                  <el-button type="primary" @click="search">查询</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="reset">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <cexport
                  url="/sys/loginLog/export"
                  :params="formInline"
                  fileName="操作日志"
                  fileType=".xls"
                ></cexport>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div class="jy_table">
        <ctable
          ref="ctable"
          selectMode="check"
          :columns="column"
          :data="tableData"
          :total="total"
          operateWidth="200"
          @update="initTable"
        >
        </ctable>
      </div>
    </div>
  </div>
</template>
<script>
import cexport from "@/components/export";
export default {
  name: "index",
  mounted() {
    this.$nextTick(() => {
      this.initTable();
    });
  },
  methods: {
    search() {
      this.$refs.ctable.reset();
      this.initTable();
    },
    initTable() {
      const page = this.$refs.ctable.getPager();
      let startTime, endTime;
      if (this.formInline.startEnd && this.formInline.startEnd.length) {
        startTime = this.formInline.startEnd[0];
        endTime = this.formInline.startEnd[1].$FixedZeroTime();
      }
      this.$http
        .post({
          url: "/sys/loginLog/queryPage",
          data: {
            ...page,
            ...this.formInline,
            startTime,
            endTime,
          },
        })
        .then((res) => {
          this.tableData = res.detail.list;
          this.total = res.detail.total;
          this.tableData.forEach((item) => {
            if (item.roles != null) {
              item.roles = item.roles.toString();
              this.$set(item, "roles", item.roles);
            }
          });
        });
    },
    async reset() {
      this.formInline = {
        userName: "",
        type: "",
        realName: "",
        startEnd: [],
        module: "",
      };
      this.$refs.ctable.reset();
      this.initTable();
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
  data() {
    return {
      formInline: {
        userName: "",
        type: "",
        realName: "",
        startEnd: [],
        module: "",
      },

      total: 0,
      showDialog: false,

      isEdit: false,

      selData: {},
      tableData: [],
      column: [
        {
          prop: "userName",
          title: "登录账号",
        },
        {
          prop: "realName",
          title: "用户名称",
        },
        {
          prop: "orgName",
          title: "所属机构",
        },
        // {
        //   prop: "roles",
        //   title: "所属角色",
        // },
        {
          prop: "module",
          title: "操作模块",
        },
        {
          prop: "operatorType",
          title: "操作类型",
        },
        {
          prop: "content",
          title: "操作详情",
        },
        {
          prop: "ip",
          title: "操作IP",
        },
        {
          prop: "createTime",
          title: "操作日期",
        },
      ],
    };
  },
  components: {
    // orgTreeDialog,
    //  pagination
    cexport,
  },
};
</script>
