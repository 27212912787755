<template>
    <el-button :loading="loading" @click="exportChecked" type="primary">{{
        buttonName
    }}</el-button>
</template>

<script>
// 需使用jquery ajax来检测下载进度
export default {
    name: "exportModular",
    data() {
        return {
            loading: false
        };
    },
    props: {
        url: {
            type: String,
            required: true
        },
        params: {
            type: Object
        },
        fileName: {
            type: String,
            required: true
        },
        buttonName: {
            type: String,
            default: "导出"
        },
        fileType: {
            type: String,
            default: ".xls"
        }
    },
    methods: {
        // 导出
        exportChecked() {
            this.loading = true;
            // const vm = this;
            setTimeout(() => {
                if (this.loading) {
                    this.loading = false;
                }
            }, 5 * 60 * 1000);
            // this.ajaxExport(this.url, this.params)
            //     .then(res => {
            //         vm.downloads(res);
            //     })
            //     .catch(err => {
            //         vm.downloads(err);
            //     });
            this.exportAjax({
                url: this.url,
                data: this.params
            })
                .then(res => {
                    this.loading = false;
                    this.downloads(res);
                })
                .catch(res => {
                    this.loading = false;
                    this.downloads(res);
                });
        },

        downloads(data) {
            if (!data) {
                return false;
            }
            const url = window.URL.createObjectURL(new Blob([data]), {
                type: data.type
            });
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", this.fileName + this.fileType);
            document.body.appendChild(link);
            link.click();
        },

        ajaxExport(url, data) {
            const vm = this;
            return new Promise(function (resolve, reject) {
                $.ajax({
                    xhr: function() {
                        var xhr = new window.XMLHttpRequest();
                        //Download progress
                        xhr.addEventListener(
                            "progress",
                            function(evt) {
                                console.log(evt.total)
                                if (
                                    evt.lengthComputable &&
                                    evt.loaded / evt.total == 1
                                ) {
                                    vm.loading = false;
                                }
                            },
                            false
                        );
                        return xhr;
                    },
                    type: "post",
                    url: window.config.baseUrl + url,
                    contentType: "application/json;charset=utf-8",
                    context: vm,
                    beforeSend: function(xhr){
                        let token = this.$util.getStor("token");
                        if(token != ""){
                            xhr.setRequestHeader("token",token)
                        }
                    },
                    data: JSON.stringify(data),
                    success: function(res) {
                        resolve(res);
                    },
                    error: function(err) {
                        this.loading = false;
                        reject(err);
                    }
                });
            });
        },

        exportAjax(params) {
            return this.$http.post(params.url, params.data, {
                responseType: "blob"
            });
        }
    }
};
</script>

<style scoped></style>
